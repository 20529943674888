import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
    return request.post(constant.chargingServerUrl + "/applicationAmountRecord/pageList", formData);
}

function list(){
    return request.post(constant.chargingServerUrl + "/applicationAmountRecord/list");
}

function create(){
    return request.get(constant.chargingServerUrl + "/applicationAmountRecord/create");
}
  
function edit(id){
return request.get(constant.chargingServerUrl + "/applicationAmountRecord/edit/" + id);
}

function add(formModel){
return request.post(constant.chargingServerUrl + "/applicationAmountRecord/add", formModel,{
    headers: {
    "Content-Type": "application/json"
    }
});
}

function update(formModel){  
return request.post(constant.chargingServerUrl + "/applicationAmountRecord/update", formModel,{
    headers: {
    "Content-Type": "application/json"
    }
});
}

function remove(id){
return request.post(constant.chargingServerUrl + "/applicationAmountRecord/delete/" + id);
}

function batchRemove(idList){
return request.post(constant.chargingServerUrl + "/applicationAmountRecord/batchDelete",idList,{
    headers: {
    "Content-Type": "application/json"
    }
});
}

function toExamine(formData){
    return request.post(constant.chargingServerUrl + "/applicationAmountRecord/toExamine", formData);
}


export default {
    pageList,create,edit,add,update,remove,batchRemove,list,toExamine
}